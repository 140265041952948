import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import setTooltip from './assets/js/tooltip';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { io } from "socket.io-client";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import VueSocketIO from 'vue-socket.io';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

let socket = io(`${process.env.VUE_APP_EVENTS_ENDPOINT}/admin`, {
  withCredentials: true,
  transports: ['polling', 'websocket'],
  autoConnect: true
})

Vue.config.productionTip = false
Vue.use(new VueSocketIO({
  debug: process.env.NODE_ENV != 'prod',
  connection: socket
}));
Vue.use(VueToast)
Vue.use(require('vue-moment'))
new Vue({
  router,
  store,
  ArgonDashboard,
  render: h => h(App)
}).$mount('#app')

setTooltip()

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  let [resource, config ] = args;
  // request interceptor here
  const response = await originalFetch(resource, config);
  // response interceptor here
  if (response.status == 401) {
    return router.push('/login')
  }

  return response;
};