<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="'Dashboard'">
          <template v-slot:icon>
            <i class="fa-solid fa-house-chimney text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/statistics"
          :class="getRoute() === 'statistics' ? 'active' : ''"
          :navText="'Statistiques'">
          <template v-slot:icon>
            <i class="fa-solid fa-chart-line text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/orders"
          :class="getRoute() === 'orders' ? 'active' : ''"
          :navText="'Commandes'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-receipt text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/categories"
          :class="getRoute() === 'categories' ? 'active' : ''"
          :navText="'Catégories'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-sitemap text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/products"
          :class="getRoute() === 'products' ? 'active' : ''"
          :navText="'Produits'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-mug-hot text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/customizations"
          :class="getRoute() === 'customizations' ? 'active' : ''"
          :navText="'Personnalisations'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-cubes-stacked text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/places"
          :class="getRoute() === 'places' ? 'active' : ''"
          :navText="'Points de vente'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-caravan text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/users"
          :class="getRoute() === 'users' ? 'active' : ''"
          :navText="'Utilisateurs'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-users text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Fran's Office"
    };
  },
  components: {
    SidenavItem,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
