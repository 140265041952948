import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Tableau de bord'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      localized_name: 'Connexion'
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/Categories.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Liste des catégories'
    }
  },
  {
    path: '/categories/new',
    name: 'new-category',
    component: () => import('../views/Category.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Ajouter une nouvelle catégorie'
    },
  },
  {
    path: '/categories/:id',
    name: 'edit-category',
    component: () => import('../views/Category.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Modifier une catégorie'
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Liste des produits'
    }
  },
  {
    path: '/products/new',
    name: 'new-product',
    component: () => import('../views/Product.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Ajouter un nouveau produit'
    },
  },
  {
    path: '/products/:id',
    name: 'edit-product',
    component: () => import('../views/Product.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Modifier un produit'
    },
  },
  {
    path: '/customizations',
    name: 'customizations',
    component: () => import('../views/Customizations.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Liste des personnalisations'
    }
  },
  {
    path: '/customization/new',
    name: 'new-customization',
    component: () => import('../views/Customization.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Ajouter une nouvelle personnalisation'
    }
  },
  {
    path: '/customization/:id',
    name: 'edit-customization',
    component: () => import('../views/Customization.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Modifier une personnalisation'
    }
  },
  {
    path: '/places',
    name: 'places',
    component: () => import('../views/Places.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Liste des points de vente'
    }
  },
  {
    path: '/places/new',
    name: 'new-place',
    component: () => import('../views/Place.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Ajouter un point de vente'
    }
  },
  {
    path: '/places/:id',
    name: 'edit-place',
    component: () => import('../views/Place.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Modifier un point de vente'
    }
  },
  {
    path: '/places/:id/statistics',
    name: 'place-statistics',
    component: () => import('../views/PlaceStatistics.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Statistique d\'un point de vente'
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Liste des utilisateurs'
    }
  },
  {
    path: '/users/new',
    name: 'new-user',
    component: () => import('../views/User.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Ajouter un utilisateur'
    }
  },
  {
    path: '/users/:id',
    name: 'edit-user',
    component: () => import('../views/User.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Modifier un utilisateur'
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/Orders.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Liste des commandes'
    }
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('../views/Order.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Détail d\'une commande'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/Statistics.vue'),
    meta: {
      requiresAuth: true,
      localized_name: 'Statistiques'
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.getters.isAuthenticated) {
      next('/login')
      return
    }
  }
  next()
})

export default router
