<template>
  <div id="app" class="g-sidenav-show">
    <div class="min-height-300 position-absolute w-100 bg-primary"></div>
    <sidenav  v-if="this.$store.state.showSidenav" />
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <navbar class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl px-0 mx-4 px-0 mx-4" v-if="this.$store.state.showNavbar"/>
      <router-view />
    </main>
  </div>
</template>

<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter
  },
  created() {
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;

    this.$socket.on('connection', (socket) => {
      console.log(socket.handshake.auth)
    })

    this.$socket.on('connect_error', (error) => {
      console.log(error.message, error.description, error.context)
      if (error.message === "invalid credentials") {
        socket.auth.token = this.$store.accessToken;
        socket.connect();
      }
    })
  }
};
</script>
