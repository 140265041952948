import { FransApi } from '@/services/frans-api.service'
import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    refresh: null,
    showSidenav: true,
    showNavbar: true
  },
  getters: {
    isAuthenticated: state => state.token != null,
    accessToken: state => state.token
  },
  mutations: {
    setToken(state, {access_token, refresh_token}) {
      state.token = access_token
      state.refresh = refresh_token
    },
    logOut(state) {
      state.token = null
      state.refresh = null
    }
  },
  actions: {
    async authenticate({commit}, {email, password}) {
      let data = await FransApi.authenticate(email, password)
      await commit('setToken', data)
    }
  },
  modules: {
  },
  plugins: [CreatePersistedState()]
})
